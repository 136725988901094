body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;

  font-weight: 300;
  text-transform: lowercase;
  background-color: var(--color-primary);
  color: var(--text-primary);
}

a {
  color: var(--link-color);
  transition: 125ms ease-in-out all;
  padding: 0.25rem;
}

p {
  margin-bottom: 0.25rem;
}

a:hover {
  color: var(--color-primary);
  background-color: var(--link-color);
}

.wrapper {
  flex: 1;
  max-width: 1300px;
  margin: auto;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 2rem;
}

@media (max-width: 786px) {
  .grid {
    grid-template-columns: repeat(1, 100%);
    padding: 1rem;
  }
}

.grid-item {
  border-radius: 1rem;
}

.grid-item img {
  border-radius: 1rem;
}

.grid-item.description {
  border: 1px solid var(--border-color);
  text-align: right;
  padding: var(--padding-main);
  background-color: #dbe2e4;
}

.electrical-specs {
  background-color: var(--color-primary);
  border-collapse: collapse;
  border-radius: 1rem;
  font-family: "Monaco", "Courier New", Courier, monospace;
  overflow: hidden;
  border: 1px solid var(--border-color);
  padding: 0;
  margin: 2rem auto;
  width: 100%;
  font-size: 0.9rem;
}

.electrical-specs thead {
  border-bottom: 1px solid var(--border-color);
}

.electrical-specs th {
  color: #8a8d8e;
}

.electrical-specs th,
.electrical-specs td {
  padding: 0.5rem 1rem;
  text-align: center;
}

.materials-list {
  font-family: "Monaco", "Courier New", Courier, monospace;
  color: #8a8d8e;
  margin-bottom: 1rem;
}
