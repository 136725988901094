:root {
  --text-primary: #34495e;
  --color-primary: #ecf0f1;
  --color-secondary: #34495e;
  --padding-main: 1rem;
  --margin-main: 1rem;
  --link-color: #f39c12;
  --border-color: #d5d5d5;
}

html[data-theme="light"] {
  --text-primary: #34495e;
  --color-primary: #ecf0f1;
  --color-secondary: #34495e;
  --link-color: #f39c12;
}

html[data-theme="dark"] {
  --text-primary: #ecf0f1;
  --color-primary: #34495e;
  --color-secondary: #ecf0f1;
  --link-color: #f39c12;
}
