.wrapper {
  border-top: 1px solid var(--border-color);
  padding: var(--padding-main);
  color: var(--color-secondary);
  background-color: var(--color-primary);
}
.title {
  font-size: 1.3rem;
  color: var(--color-primary);
}
