.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: var(--padding-main);
  color: var(--text-primary);
  background-color: var(--color-primary);
}
.section {
  flex: 1;
}
.section.start {
  text-align: left;
}
.section.middle {
  text-align: center;
}
.section.end {
  text-align: right;
}
.title {
  font-size: 1.3rem;
  flex: 1;
}
